import songs from "./songs.json";

const defaultSong = encodeURIComponent(songs[0]);

const listHolder = document.querySelector("#list");

if (listHolder == null) {
  throw new Error("Could not find list element");
}

async function loadSong(encoded: string) {
  if (XMPlayer.playing) {
    XMPlayer.stop();
    XMView.stop();
  }

  const result = await fetch(`songs/${encoded.replace("%2F", "/")}`);
  const arrayBuf = await result.arrayBuffer();

  if (!XMPlayer.load(arrayBuf)) {
    throw new Error("Failed to load file");
  }

  XMView.init();

  document.title = XMPlayer.xm.songname || decodeURIComponent(encoded);
}

for (const song of songs) {
  const listItem = document.createElement("li");
  const link = document.createElement("a");
  link.innerText = song;
  link.href = `#${encodeURIComponent(song)}`;
  listItem.appendChild(link);
  listHolder.appendChild(listItem);
}

window.addEventListener("hashchange", async () => {
  await loadSong(window.location.hash.substr(1));

  XMPlayer.play();
});

(async () => {
  XMPlayer.init();
  const songInHash = window.location.hash?.substr(1);
  await loadSong(songInHash || defaultSong);
})();
